const Metar = ({ metar }) => {
  return (
    <>
      <h2>METAR</h2>
      <div className="alert alert-light" role="alert">
        {metar}
      </div>
    </>
  );
};

export default Metar;
