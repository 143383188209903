import React, { useEffect, useState } from 'react';
import { request } from '../utils/network-client';
import { useNavigate, useParams } from 'react-router-dom';
import { getJwtTokenCookie } from '../utils/authentication';
import Metar from '../components/Metar';
import ListenersList from './components/ListenersList';
import Spinner from '../components/Spinner';
import AudioPlayer from '../components/AudioPlayer';
import { calculateActiveTime } from '../utils/utils';
import Recordings from './components/Recordings';

const FETCH_INTERVAL = 10 * 1000; // 10 seconds

const StreamDetailsPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = getJwtTokenCookie();

    if (!jwtToken) {
      navigate('/');
    }

    // eslint-disable-next-line
  }, []);

  const { id } = useParams(); // get parameter from url

  const [stream, setStream] = useState(undefined);
  const [metar, setMetar] = useState('Loading ...');
  const [loading, setLoading] = useState(false);
  const [streamActiveTime, setStreamActiveTime] = useState();

  // Set window title
  useEffect(() => {
    if (stream) {
      document.title = `Stream ${stream.name} | Говори Кула`;
    }
  }, [stream]);

  // Fetch streams
  useEffect(() => {
    const fetch = (showLoading = false) => {
      showLoading && setLoading(true);

      request('get', `streams/${id}`)
        .then((resp) => {
          setStream(resp.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetch(true);
    // Constantly fetch in order to keep the details up-to-date without refreshing the page
    const intervalId = setInterval(fetch, FETCH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [id]);

  // Fetch METAR
  useEffect(() => {
    if (!stream) return;

    if (!stream.icao) {
      setMetar('Няма METAR информация');
      return;
    }

    request('get', `metar/${stream.icao}`).then((resp) => {
      setMetar(resp.data);
    });
  }, [stream]);

  // Calculate the active time
  useEffect(() => {
    if (!stream) return;

    // initial set
    setStreamActiveTime(calculateActiveTime(stream.startedOn));

    const intervalId = setInterval(() => {
      setStreamActiveTime(calculateActiveTime(stream.startedOn));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [stream]);

  return (
    <>
      <h1>Стрийм {stream?.name}</h1>

      {loading && <Spinner />}

      {stream && (
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <h2>Детайли</h2>
              <strong>Stream name:</strong> <em>{stream.name}</em> <br />
              <strong>Stream Id:</strong> <em>{stream.id}</em> <br />
              <strong>Active time:</strong> <em>{streamActiveTime}</em> <br />
              <hr />
              <strong>Airport:</strong> <em>{stream.airportName}</em> <br />
              <strong>ICAO:</strong> <em>{stream.icao}</em> <br />
              <strong>Channel:</strong> <em>{stream.channel}</em>
              <hr />
              <Recordings streamName={stream.name} />
              <hr />
              <h2>Слушайте стрийма</h2>
              <AudioPlayer streamId={stream.id} streamPath={stream.path} />
              <hr className="d-block d-lg-none" />
            </div>
            <div className="col-lg">
              <Metar metar={metar} />
              <ListenersList listeners={stream.listeners} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StreamDetailsPage;
