import axios from 'axios';
import { getJwtTokenCookie, logout } from './authentication';

export const request = (method, endpoint, queryParams, requestBody, logoutOnFailure = true) => {
  const jwtToken = getJwtTokenCookie();

  const sentRequest = axios
    .request({
      method,
      url: endpoint,
      params: queryParams,
      data: requestBody,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
    .catch((error) => {
      const resp = error.response;
      if (logoutOnFailure && (resp.status === 401 || resp.status === 403)) {
        logout();
      }
      return resp;
    });

  return sentRequest;
};

export const downloadFile = (path) => {
  const jwtToken = getJwtTokenCookie();

  const sentRequest = axios
    .request({
      method: 'GET',
      url: path,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
    .then((response) => {
      const urlParts = path.split('/');
      const fileName = urlParts[urlParts.length - 1]; // extract the file name from the download path

      const blob = new Blob([response.data], { type: 'audio/mpeg' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      const resp = error.response;
      if (resp.status === 401 || resp.status === 403) {
        logout();
      }
      return resp;
    });

  return sentRequest;
};
